<template>
  <ion-page>
    <ion-header class="headerTop ion-no-border">
      <headertoolbar></headertoolbar>
    </ion-header>
    <ion-content :fullscreen="true">
      <div class="original_main_container sucursales_content">
        <ion-header collapse="condense"></ion-header>
        <ion-icon class="l_close_a" button @click="this.$router.push('/admin/dashboard')"
          src="assets/ICONOS/close.svg"></ion-icon>

              <p class="tabTitle">Sucursales
                <ion-icon class="config_icon_question" src="assets/ICONOS/questionmark.svg" 
                    @click="openInfoModal('Sucursales', 'Este reporte muestra la lista de sucursales ordenadas alfabéticamente del canal seleccionado. Cada sucursal tiene un enlace directo, para que los usuarios no tengan que seleccionar manualmente su sucursal y la variante de contenido.', 

[
{
    id: 0,
    mrg: 15,
    name: 'Si planea usar estos enlaces directos, le recomendamos ocultar las variantes de contenido y sucursales en el apartado de configuraciones. De esta manera, los usuarios no tendrán que hacer nada para identificarse.'
  },{
    id: 0,
    mrg: 15,
    name: 'Para facilitar el envío de estos enlaces, hay dos botones de copiado rápido:'
  },{
    id: 0,
    mrg: 5,
    name: ' 1. Botón Blanco: Copia el enlace completo.'
  },{
    id: 0,
    mrg: 5,
    name: ' 2. Copy de WhatsApp: Copia el enlace junto con una pequeña instrucción sobre cómo guardarlo en el navegador (diseñado para WhatsApp, pero se puede utilizar en otras aplicaciones).'
  }
])"
                    />
                  </p>

                <select class="channel_select_e" v-model="selectedChannel" @change="handleChannelChange" aria-label="Canal" interface="popover" placeholder="Selecciona el canal">
                  <option v-for="item in channel_name_list" :key="item.id" :value="item.id">
                    {{ item.name }}
                  </option>
                </select>

                <div class="admin_report_filter">
                  <div class="filter-input-con" >
                    <ion-input class="filterInput" placeholder="Filtrar por sucursal" @keyup="filterSucursales" ref="textFilter"></ion-input>
                    <ion-icon class="filterIcon filtersearch" src="assets/ICONOS/search.svg"></ion-icon>
                    <ion-icon src="assets/ICONOS/close_f.svg" class="filterIcon filterclose" @click="this.$refs.textFilter.value = ''; filterSucursales();"></ion-icon>
                  </div>
                </div>
                
                <div class="out_loader" v-if="isloading">
                  <div class="loader"></div>
                </div>
                <ion-card v-if="sucursales.length<=0 && isloading == false" color="warning" class="connectionalert outline-card" ouline>
                  <ion-card-header>
                    <ion-card-title>Sin Sucursales.</ion-card-title>
                  </ion-card-header>
                  <ion-card-content>
                    Usted no cuenta con sucursales registradas... contacte con su asesor de marca para más información.
                  </ion-card-content>
                </ion-card>
                <div v-if="!isloading">

                  <ion-grid >
                    <ion-row v-for="suc in sucursalesFiltered" :key="suc.id" class="suc_list_row">
                      <ion-col size="5" class="suc_list_col suc_list_col1">
                        {{ suc.name }}
                      </ion-col> 
                      <ion-col size="5" class="suc_list_col suc_list_col2">
                        <CopyToClipboard v-slot="props">
                          <div class="copy_link" @click="props.copy( construirEnlace(suc, dashboard.radio.link) )"
                            :disabled="props.status === 'copied'" :class=props.status>
                            <ion-icon class="icon_l" slot="start" src="assets/ICONOS/links-gris.svg"></ion-icon>
                            <strong class="text_to_copy">{{construirEnlace(suc, dashboard.radio.link)}}</strong>
                            <strong class="text_copiado">¡Link <span>Copiado</span>!</strong>
                          </div>
                        </CopyToClipboard>
                      </ion-col>
                      <ion-col size="2" class="suc_list_col suc_list_col3 wpcc">
                        <CopyToClipboard v-slot="props" >
                          <div class="copy_link_button" @click="props.copy( 'Hola, este es el *link* de tu *sucursal*:\n*'+ suc.name +'*\n'+construirEnlace(suc, dashboard.radio.link)+'\nFavor de utilizar este de ahora en adelante, para identificarte de manera automática.\nTe recomendamos lo dejes como favorito en tu navegador para un rápido acceso.' )"
                            :disabled="props.status === 'copied'" :class=props.status>
                            <ion-icon class="wp_img_c" src="assets/ICONOS/whatsapp.svg"></ion-icon>
                            <strong class="text_to_copy">Copy de WhatsApp</strong>
                            <strong class="text_copiado">¡Link <span>Copiado</span>!</strong>
                          </div>
                        </CopyToClipboard>
                      </ion-col> 
                    </ion-row>
                  </ion-grid>
                </div>


      </div>
    </ion-content>
    <!-- <ion-button @click="signOut" class="logoutButtonCabina"><ion-icon src="assets/ICONOS/cabina/log-out-outline.svg"></ion-icon>Cerrar sesión</ion-button> -->
  </ion-page>
</template>


<script>
/* eslint-disable */
import { alertController, IonPage, IonHeader, IonToolbar, IonContent, IonRefresher, IonRefresherContent, modalController } from '@ionic/vue';
import axios from 'axios';
import CopyToClipboard from '../CopyToClipboard.vue';
import { defineComponent } from 'vue';
import { Storage } from '@capacitor/storage';
import headertoolbar from '../header_toolbar.vue';
import { Capacitor } from '@capacitor/core';
import 'url-polyfill';
import 'blob-polyfill';

import infoModal from '../infoModal.vue';
//import { toastController } from '@ionic/vue';

export default ({
  name: 'Reproductor',
  components: { IonHeader, IonToolbar, IonContent, IonPage, headertoolbar, IonRefresher, CopyToClipboard, IonRefresherContent },
  data() {
    return {
      urlApi: 'https://panel.vybroo.net/',
      clientData: null,  
      token: null,
      serverDate: null,
      clientData: {}, 
      channel_name_list: [],
      channel_list: [],
      selectedChannel: null,
      sucursales: [],
      sucursales_data: [],
      sucursalesFiltered: '',
      dashboard: 
        {
          client: {
            email: "",
            id: 0,
            name: "",
            obligation: 0,
            retainer: 0
          },
          radio: {
            blacklist: "",
            branches: 0,
            categories: "",
            image: "2f07f7cfc8d4348937078a566c7d943e.png",
            link: "",
            mobileimage: "6b8b53effc9683373db10671d4bf0687.png",
            name: "",
            tags_blacklist: "",
            video_enabled: false
          },
          spots: {
            id: 0,
            level: 0,
            month: "",
            name: "",
            obligation: 0,
            percentage: "",
            produced: null,
            year: ""
          },
        },
        isloading: true,
    }
  },
  methods: { 
    async openInfoModal(title, desc, list) {
      const modal = await modalController.create({
        component: infoModal,
        componentProps: {
          title: title,
          desc: desc,
          list: list,
        },
      });
      return modal.present();
    },
    filterSucursales() {
      let filter = (this.$refs.textFilter.value).toLowerCase();
      //console.log('filter:', filter);
      this.sucursalesFiltered = this.sucursales.filter(sucursal => {
        return sucursal.name.toLowerCase().includes(filter)/* ||
              sucursal.variant_name.toLowerCase().includes(filter) ||
              sucursal.ip.toLowerCase().includes(filter)*/;
      }); 
    },
    handleChannelChange(event) {
      this.isloading = true;
      this.sucursales = [];
      this.sucursales_data = [];
      this.sucursalesFiltered = [];
      this.selectedChannel = event.target.value;
      this.emitter.emit("selectedChannel", event.target.value);
              Storage.set({
                key: "cabina_admin_selectedChannel",
                value: this.selectedChannel,
              }); 
      
      setTimeout(() => {
        this.getSucursalesData(); 
      }, 1000);
    },
    async getSucursalesData() {
      this.isloading = true;
      try {
    this.sucursales = [];
    this.sucursales_data = [];
    let sucursalesAux = [];
    sucursalesAux.variants = this.dashboard.radio.ContentVariants;
    sucursalesAux.identifiers = this.dashboard.radio.identifiers;

    sucursalesAux.variants.forEach(variant => {
        let newData = { name: variant.name, var: variant.id };
        let existingData = this.sucursales_data.find(item => item.name === variant.name);
        if (existingData) {
            existingData.var = variant.id;
        } else {
            this.sucursales_data.push(newData);
        }
    });

    sucursalesAux.identifiers.forEach(identifier => {
        let newData = { name: identifier.identifier, suc: identifier.id };
        let existingData = this.sucursales_data.find(item => item.name === identifier.identifier);
        if (existingData) {
            existingData.suc = identifier.id;
        } else {
            this.sucursales_data.push(newData);
        }
    });

    this.sucursales = this.sucursales_data;
    //ordenar por nombre
    this.sucursales.sort((a, b) => (a.name > b.name) ? 1 : -1);

    console.log('=====sucursales', this.sucursales);
    this.filterSucursales();
    this.isloading = false;
} catch (error) {
    console.log("Error en función getSucursalesData de Conexiones: " + error.message);
}

    },
    construirEnlace(sucursal, linkBase) {
        let urlType = 'app.vybroo.net';
        if (this.dashboard.radio.sonara) {
            urlType = 'sonara.red';
        }
        let sucursalURL = 'no disponible';
        if (sucursal.suc && sucursal.var) {
            sucursalURL = `https://${urlType}/${linkBase}?sucursal=${sucursal.suc}&variante=${sucursal.var}`;
        } else if (sucursal.suc) {
            sucursalURL = `https://${urlType}/${linkBase}?sucursal=${sucursal.suc}`;
        } else if (sucursal.var) {
            sucursalURL = `https://${urlType}/${linkBase}?variante=${sucursal.var}`;
        }
        return sucursalURL;
    }, 
    async presentAlertConfirm(headerAlert, messageAlert, buttonTextConfirm, buttonTextCancel, functionConfirm, functionCancel) {
      const alert = await alertController
        .create({
          header: headerAlert,
          message: messageAlert,
          buttons: [
            {
              text: buttonTextConfirm,
              handler: () => {
                functionConfirm();
              }
            },
            {
              text: buttonTextCancel,
              handler: () => {
                functionCancel();
              }
            }
          ]
        });
      return alert.present();
    },
    async signOut() {
      const alert = await alertController
        .create({
          header: "Aviso",
          message: "¿Estás seguro que deseas cerrar la sesión?",
          buttons: [
            {
              text: 'Si',
              handler: () => {
                Storage.remove({ key: 'authToken' });
                Storage.remove({ key: 'cabina_admin_user' });
                Storage.remove({ key: 'cabina_admin_channel_list' });
                Storage.remove({ key: 'cabina_admin_selectedChannel' });
                this.$router.replace("/admin/LoginAdmin");
                this.emitter.emit('cabina_admin_user', '',);
                this.emitter.emit('cabina_admin_channel_list', '',);
              }
            },
            {
              text: 'Cancelar',
              handler: () => {
                //console.log('Cancelar pulsado');
              }
            },
          ]
        });
      return alert.present();

    }, 
    async getStorageClientData(keyName) {
      try {
        const ret = await Storage.get({ key: keyName });
        this.clientData = this.isJson(ret.value);
      } catch (error) {
        //console.log("Error en función getStorageClientData de Conexiones: " + error.message);
      }
    }, 
    isJson(str) {
      try {
        const parsedStr = JSON.parse(str);
        return parsedStr;
      } catch (e) {
        return str;
      }
    },
    async getStorageAuthToken() {
      try {
        //console.log("Obteniendo token del storage...")
        const ret = await Storage.get({ key: 'authToken' });
        if (ret.value) {
          const tokenObj = JSON.parse(ret.value);
          this.token = tokenObj.value;
          //console.log("Token obtenido: " + this.token);
          // console.log("Token obtenido:" + this.token)
          return this.validateToken(this.token);
        } else {
          //console.log("Token no encontrado");
          this.presentAlertTokenInvalid();
          return false;
        }
      } catch (error) {
        //console.log("Error en función getStorageAuthToken de Conexiones: " + error.message);
      }
    },
    async presentAlertTokenInvalid() {
      const alert = await alertController
        .create({
          header: "Aviso",
          message: "Tu sesión ha expirado, por favor inicia sesión nuevamente",
          buttons: [
            {
              text: 'Aceptar',
              handler: () => {
                this.$router.replace("/admin/LoginAdmin");
              }
            },
          ]
        });
      return alert.present();
    },
    async validateToken(token) {
      try {
        //console.log("revisando validez de token: " + token + " ...");
        if (token == null || token == undefined || token == "") {
          this.presentAlertTokenInvalid();
          return false;
        }
        if (token != null && token != undefined && token != "") {
          let url = "api/user_auth";
          if (Capacitor.getPlatform() == 'ios') url = "https://panel.vybroo.net/user_auth";
          let response = await axios.post(url, { token: token })
            .then(r => {
              //console.log("r.data:");
              //console.log(r.data);
              return r;
            }).catch(error => {
              //console.log("3Error en función validateToken de Conexiones: " + error.message);
              return error;
            });
          //console.log("response.data:");
          //console.log(response.data);
          //console.log("response.data.success: " + response.data.success);
          //console.log("response.data.error: " + response.data.error);
          if (response.data.error === true) {
            //console.log("Error al validar token:");
            //console.log(response.data);
            this.presentAlertTokenInvalid();
            return false;
          }
          if (response.data.success === true) {
            //console.log("Token válido");
            return true;
          } else {
            //console.log("Token inválido");
            //console.log("2response.data.success: " + response.data.success);
            //console.log("2response.data.error: " + response.data.error);
            this.presentAlertTokenInvalid();
            return false;
          }
        }
      } catch (error) {
        //console.log("4Error en función validateToken de Conexiones: " + error.message);
        return false;
      }
    },
    async getFullClientData() {
      try {
        const ret = await Storage.get({ key: "clientData" });
        if (ret.value) {
          this.clientData = JSON.parse(ret.value);
          //console.log("Recuperado de storage, key: clientData - " + this.clientData);

          if (this.clientData.sonara) {
            this.emitter.emit("sonara", true);
          } else {
            this.emitter.emit("sonara", false);
          }
        } else {
          //console.log("No se pudo recuperar de storage, key: clientData - " + this.clientData);
        }
      } catch (e) {
        this.presentAlertConfirm("Ha ocurrido un error en la función getFullClientData", e);
      }
    },
    async getChannelList(){
      try {
        const ret = await Storage.get({ key: "cabina_admin_channel_list" });
        if (ret.value) { 
            this.channel_list = JSON.parse(ret.value).slice();
            this.emitter.emit("channel_list", this.channel_list);
            this.channel_name_list = [];
            if (this.channel_list){
              this.channel_list.forEach(element => {
                this.channel_name_list.push({ id: element.id, name: element.name });
              });
              this.emitter.emit("channel_name_list", this.channel_name_list);
              //console.log("Recuperado de storage, key: cabina_admin_channel_list - " , this.channel_list);
              //console.log("Recuperado de storage, key: channel_name_list - " , this.channel_name_list);
              if (this.selectedChannel == [] || this.selectedChannel == null || this.selectedChannel == undefined) {
                const selectedChannelAux = await Storage.get({ key: "cabina_admin_selectedChannel" });
                //console.log("selectedChannelAux: " , selectedChannelAux);
                if (selectedChannelAux.value) {
                  this.selectedChannel = selectedChannelAux.value;
                  this.emitter.emit("selectedChannel", this.selectedChannel);
                  Storage.set({
                    key: "cabina_admin_selectedChannel",
                    value: this.selectedChannel,
                  });
                } else {
                  this.selectedChannel = this.channel_list[0].id;
                  this.loadChannelDashboard(this.selectedChannel);
                  this.emitter.emit("selectedChannel", this.selectedChannel);
                  Storage.set({
                    key: "cabina_admin_selectedChannel",
                    value: this.selectedChannel,
                  });
                }
                //this.selectedChannel = this.channel_list[0].id;
                //this.loadChannelDashboard(this.selectedChannel);
                //this.emitter.emit("selectedChannel", this.selectedChannel);
              }
            }
        } else {
          console.log("No se pudo recuperar de storage, key: cabina_admin_channel_list - " , this.channel_list);
        }
      } catch (error) {
        console.log("Error en función cabina_admin_channel_list de Dashboard: " , error.message);
        return false;
      }
    }
  },
  mounted: function () { 
setTimeout(() => {

    try {
      if (Capacitor.getPlatform() == 'ios') {
        this.urlApi = "https://panel.vybroo.net/";
      } else {
        this.urlApi = "/api/";
      } 

      //get auth token de storage y asignarlo a la variable token
      this.getStorageAuthToken();
      this.getStorageClientData('clientData');
      this.getChannelList();

      this.emitter.on("selectedChannel", (channel) => {
        //console.log("conexiones::::emitedON:selectedChannel: " , channel);
        this.selectedChannel = channel;
        this.getSucursalesData(); 
      });
      this.emitter.on("channel_list", (channel_list) => {
        //console.log("conexiones::::emitedON:channel_list: " , channel_list);
        this.channel_list = channel_list;
      });
      this.emitter.on("channel_name_list", (channel_name_list) => {
        //console.log("conexiones::::emitedON:channel_name_list: " , channel_name_list);
        this.channel_name_list = channel_name_list;
      });
      this.emitter.on("dashboard", (dashboard) => {
        //console.log("conexiones::::emitedON:dashboard: " , dashboard);
        this.dashboard = dashboard; 
      });
 
    } catch (error) {
      console.log("Error en función mounted de Conexiones: " + error.message);
    }

     
}, 1000);
  setTimeout(() => {
    this.getSucursalesData(); 
  }, 2000);
  },
  

})
</script>
<style lang="css">

.sucursales_content .wp_img_c{
    width: 20px;
    height: 20px;
    margin-right: 5px;
    margin-top: 0px;
    margin-bottom: -5px;
}

.sucursales_content .link_image {
  background-position: center center;
  background-size: 90%;
  background-repeat: no-repeat;
  background-color: #FFF;
}

.sucursales_content .copy_link strong {
  color: grey;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  width: calc(100% - 35px);
  position: absolute;
}

.sucursales_content .copy_link strong span {
  color: black;

}

.sucursales_content .copy_link .icon_l {
  font-size: 16px;
  margin-bottom: -3px;
  margin-right: 2px;
}

.sucursales_content .copy_link {
  background: #FFF;
  font-size: 10px;
  padding: 6px 3px; 
  color: black;
  border-radius: 5px;
}

.sucursales_content .copy_link.copied {
  background: #b4ffc2;
}

.sucursales_content .copy_link_button { 
  font-size: 10px;
  padding: 4px 3px;  
  border-radius: 5px;
  cursor: pointer;
}


.sucursales_content .link_item {
  background-color: #2b2b2b;
  --background: #2b2b2b;
  margin: 10px 0px;
  border-radius: 10px;
}

.sucursales_content .link_items {
  padding: 10px;
  background-color: transparent;
  /*max-width: 450px;*/
  margin: auto;
}

.sucursales_content .idle .text_to_copy {
  display: initial;
  cursor: pointer;
}

.sucursales_content .copied .text_to_copy {
  display: none;
}

.sucursales_content .idle .text_copiado {
  display: none;
}

.sucursales_content .copied .text_copiado {
  display: initial;
} 

.suc_list_row{
  border-bottom: 1px solid #e0e0e0;
  padding: 0px 0px;
}
.suc_list_row:hover{
  background-color: #141414;
}
.suc_list_row .suc_list_col {
    
}
.suc_list_row .suc_list_col1 {
  width: calc(50% - 75px) !important;
  max-width: calc(50% - 75px) !important;
  min-width: calc(50% - 75px) !important;
  font-size: 15px;
  line-height: 26px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.suc_list_row .suc_list_col2 {
  width: calc(50% - 75px) !important;
  max-width: calc(50% - 75px) !important;
  min-width: calc(50% - 75px) !important;
}
.suc_list_row .suc_list_col3 {
  width: 150px !important;
  max-width: 150px !important;
  min-width: 150px !important;
}
@media only screen and (max-width: 767px) {
  .suc_list_row {
    margin: 6px;
    border: solid 1px #333;
    border-radius: 4px;
    padding: 0px 4px 4px 4px;
  }
  .suc_list_row .suc_list_col1 {
    width: calc(100% - 150px) !important;
    max-width: calc(100% - 150px) !important;
    min-width: calc(100% - 150px) !important;
    order: 1;
  }
  .suc_list_row .suc_list_col2 {
    width: 100% !important;
    max-width: 100% !important;
    min-width: 100% !important;
    order: 3;
  }
  .suc_list_row .suc_list_col3 {
    width: 150px !important;
    max-width: 150px !important;
    min-width: 150px !important;
    order: 2;
  }
}
@media only screen and (max-width: 480px) {
 
  .suc_list_row .suc_list_col1 {
    width: calc(100% - 36px) !important;
    max-width: calc(100% - 36px) !important;
    min-width: calc(100% - 36px) !important;
    order: 1;
  }
  .suc_list_row .suc_list_col2 {
    width: 100% !important;
    max-width: 100% !important;
    min-width: 100% !important;
    order: 3;
  }
  .suc_list_row .suc_list_col3 {
    width: 36px !important;
    max-width: 36px !important;
    min-width: 36px !important;
    order: 2;
  }
  .suc_list_row .suc_list_col3 .text_to_copy{
    display: none;
  }
  
  .sucursales_content .wpcc .copied .text_copiado {
    display: initial;
    position: absolute;
    right: 10px;
    top: -15px;
    background: red;
    padding: 2px 5px;
    border-radius: 3px;
  }
  .sucursales_content .wpcc .copied .text_copiado::before {
    content: '';
    position: absolute;
    width: 0;
    height: 0;
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
    border-top: 5px solid red;
    top: 100%;
    right: 5px;
  }
  .sucursales_content .wp_img_c{
    width: 20px;
    height: 20px;    
    margin-right: 0px;
  }
}

@media only screen and (max-width: 400px) {
  .sucursales_content .copy_link strong {
    font-size: 8px;
    line-height: 14px;
  }
}

@media only screen and (max-width: 380px) {
  .sucursales_content .copy_link strong {
    width: calc(100% - 35px);
  }

  .sucursales_content .copy_link .icon_l {
    font-size: 12px;
    line-height: 12px;
  }
}

</style>